// Generated by Framer (ae50a0d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {nZLce6viD: {hover: true, pressed: true}};

const serializationHash = "framer-D8p8z"

const variantClassNames = {nZLce6viD: "framer-v-1mgdoom"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, p143GZqCt: click ?? props.p143GZqCt} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, p143GZqCt, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "nZLce6viD", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapz3zo6t = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (p143GZqCt) {const res = await p143GZqCt(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1mgdoom", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"nZLce6viD"} onTap={onTapz3zo6t} ref={refBinding} style={{backgroundColor: "rgba(255, 255, 255, 0.2)", borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, ...style}} variants={{"nZLce6viD-hover": {backgroundColor: "rgba(255, 255, 255, 0.28)"}, "nZLce6viD-pressed": {backgroundColor: "rgba(255, 255, 255, 0.48)"}}} {...addPropertyOverrides({"nZLce6viD-hover": {"data-framer-name": undefined}, "nZLce6viD-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-14xqefj"} data-framer-name={"Ic-cross-20"} layout={"position"} layoutDependency={layoutDependency} layoutId={"gSpm5mg7L"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 16 16\"><path d=\"M 4 4 L 12 12 M 4 12 L 12 4\" fill=\"transparent\" stroke-width=\"1.2\" stroke=\"var(--token-52b9f1ec-8a5c-4a68-9b06-4560bba78883, rgb(255, 255, 255)) /* {&quot;name&quot;:&quot;primary-white&quot;} */\" stroke-linecap=\"square\" stroke-miterlimit=\"10\"></path></svg>"} svgContentId={12087487853} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-D8p8z.framer-nlv844, .framer-D8p8z .framer-nlv844 { display: block; }", ".framer-D8p8z.framer-1mgdoom { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 10px; position: relative; width: min-content; }", ".framer-D8p8z .framer-14xqefj { flex: none; height: 16px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-D8p8z.framer-1mgdoom { gap: 0px; } .framer-D8p8z.framer-1mgdoom > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-D8p8z.framer-1mgdoom > :first-child { margin-left: 0px; } .framer-D8p8z.framer-1mgdoom > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"MESAIm26Z":{"layout":["auto","auto"]},"UvOQJOiJK":{"layout":["auto","auto"]}}}
 * @framerVariables {"p143GZqCt":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerP3hGTclG8: React.ComponentType<Props> = withCSS(Component, css, "framer-D8p8z") as typeof Component;
export default FramerP3hGTclG8;

FramerP3hGTclG8.displayName = "button-56px-ic-x";

FramerP3hGTclG8.defaultProps = {height: 36, width: 36};

addPropertyControls(FramerP3hGTclG8, {p143GZqCt: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerP3hGTclG8, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})